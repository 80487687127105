define(['app', 'productAddToBasket', '$window', 'enhancedEcom'], (app, productAddToBasket, $window, enhancedEcom) => {

  const productPageStockAvailabilityModal = () => {
    const component = {};

    const _config = {
      selectors: {
        search: '.productPageStockAvailabilityModal_searchBar',
        modalInfo: '.productPageStockAvailabilityModal_info',
        locationDropDown: '.storeLocatorDropdown',
        locationSuggestions: '.storeLocatorDropdown_suggestion',
        form: '.productPageStockAvailabilityModal_form',
        storeAvailability: '.storeAvailability_container',
        localAvailablity: '.productPageStockAvailability_localAvailablity',
        availableStock: '.availableStock',
        productAddToBasket: '[data-component=productAddToBasket]',
        productAddToBasketButton: '[data-component=productAddToBasketButton]',
        infoForm: '.productPageStockAvailabilityModal_info_form',
        storeAvailabilityAddToBasketButton: '.storeAvailability_productAddToBasket_button',
        storeAvailabilityStoreInfo: '.storeAvailability_store_information',
        storeAvailabilityElem: '.storeAvailability',
        externalSku: '.externalSku',
        storeAvailable: '.storeAvailability'
      },
      LSkeys: {
        availabilitySelectedLat: 'availabilitySelectedLat',
        availabilitySelectedLon: 'availabilitySelectedLon',
        availabilitySelectedLocation: 'availabilitySelectedLocation'
      },
      attribs: {
        productId: 'data-product-id',
        callFromBasket: 'data-call-from-basket',
        disallowAddToBasket: 'data-disallow-homebase-add-to-basket',
        longitude: 'data-longitude',
        latitude: 'data-latitude',
        callFromWishlist: 'data-call-from-wishlist',
        postcode: 'data-postcode',
        quantity: 'data-product-quantity',
        externalSku: 'data-external-sku',
        upstreamErrors: 'data-service-error',
        storePosition: 'data-position-id'
      },
      subscribeChannels: {
        selectedStoreUpdate: 'storeSelected/postcodeLookup',
        openStockCheckClick: 'openStockCheck/click',
        storeIdDisplay: 'storeIdDisplay'
      }
    }

    const _init = (element) => {
      component.element = element;
      component.search = component.element.querySelector(component.config.selectors.search);
      component.modalInfo = component.element.querySelector(component.config.selectors.modalInfo);
      component.form = component.element.querySelector(component.config.selectors.form);
      component.infoForm = component.element.querySelector(component.config.selectors.infoForm);
      component.localAvailablity = document.querySelector(component.config.selectors.localAvailablity);
      component.availableStock = document.querySelector(component.config.selectors.availableStock);

      if (component.element.hasAttribute(component.config.attribs.callFromBasket)) {
        component.callFromBasket = component.element.getAttribute(component.config.attribs.callFromBasket) === 'true';
      } else {
        component.callFromBasket = false;
      }
      if (component.element.hasAttribute(component.config.attribs.callFromWishlist)) {
        component.callFromWishlist = component.element.getAttribute(component.config.attribs.callFromWishlist) === 'true';
      } else {
        component.callFromWishlist = false;
      }

      app.subscribe(component.config.subscribeChannels.openStockCheckClick, () => {
        component.populateStockCheckerFromLocalStorage(component.getProductId());
      })

      app.subscribe(component.config.subscribeChannels.storeIdDisplay, component.storeButtonClicked);

      component.addEventListeners();

      return component;
    }

    const _addEventListeners = () => {
      component.search && component.search.addEventListener('input', component.onSearch);
      component.form && component.form.addEventListener('submit', component.submitForm);
    }

    const _populateStockCheckerFromLocalStorage = (productId) => {
      if (!isNaN(productId) && productId !== null && productId !== undefined) {
        component.element.setAttribute(component.config.attribs.productId, productId);
      } else {
        return;
      }

      if($window.localStorage.getItem(component.config.LSkeys.availabilitySelectedLat)
        && $window.localStorage.getItem(component.config.LSkeys.availabilitySelectedLon)) {

        component.search.value = $window.localStorage.getItem(component.config.LSkeys.availabilitySelectedLocation);
        const longitude = window.localStorage.getItem(component.config.LSkeys.availabilitySelectedLon);
        const latitude = $window.localStorage.getItem(component.config.LSkeys.availabilitySelectedLat);

        component.showNearbyStores(longitude, latitude);
      }
    }

    const _submitForm = (event) => {
      event.preventDefault();
      if(component.search.value.length > 0) {
        component.getRecommendedLocations(true, true);
      }
      if(component.search.value.length === 0) {
        component.displayRequiredFieldErrorMessage();
      }
    }

    const _onSearch = () => {
      if(component.search.value.length > 0) {
        component.getRecommendedLocations();
      }
      else {
        component.locationDropDown = component.element.querySelector(component.config.selectors.locationDropDown);
        component.locationDropDown && component.infoForm.removeChild(component.locationDropDown);
      }
    }

    const _getRecommendedLocations = (inBackground, formSubmitted) => {
      const searchValue = component.search.value && component.search.value;

      app.ajax.get({
        url: `/recommendedLocations.lookup?location=${searchValue}&stringTemplatePath=components/locator/partials/locatorSearch/storeLocatorDropdown&numLocations=8`,
        success: (response) => { component.searchSuccessHandler(response, inBackground); },
        error: () => {
          if(formSubmitted) {
            component.displayNoResultsFoundMessage();
          }
        }
      });
    }

    const _searchSuccessHandler = (response, inBackground) => {
      if(response) {
        component.locationDropDown = component.element.querySelector(component.config.selectors.locationDropDown);
        component.locationDropDown && component.infoForm.removeChild(component.locationDropDown);
        component.form.insertAdjacentHTML('afterend', response);
        component.locationSuggestions =
            component.element.querySelectorAll(component.config.selectors.locationSuggestions);

        if (inBackground && component.locationSuggestions) {
          if (component.locationSuggestions.length > 0) {
            const firstLocationSuggestion = component.locationSuggestions[0];
            component.handleSuggestionClicked(firstLocationSuggestion);
          } else {
            component.displayNoResultsFoundMessage();
          }
        } else {
          component.locationSuggestions && component.locationSuggestions.forEach(suggestion => {
            suggestion.addEventListener('click', () => { component.handleSuggestionClicked(suggestion) });
          });
        }
      }
    }

    const _handleSuggestionClicked = (suggestion) => {
      component.locationDropDown = component.element.querySelector(component.config.selectors.locationDropDown);
      component.locationDropDown && component.infoForm.removeChild(component.locationDropDown);
      component.checkStockModalInput = suggestion.innerHTML;
      const longitude = suggestion.getAttribute(component.config.attribs.longitude);
      const latitude = suggestion.getAttribute(component.config.attribs.latitude);
      const postcode = suggestion.getAttribute(component.config.attribs.postcode);
      $window.localStorage.setItem(component.config.LSkeys.availabilitySelectedLat, latitude);
      $window.localStorage.setItem(component.config.LSkeys.availabilitySelectedLon, longitude);
      $window.localStorage.setItem(component.config.LSkeys.availabilitySelectedLocation, postcode);

      component.showNearbyStores(longitude, latitude);
    }

    const _getProductId = () => {
      if (component.element.hasAttribute(component.config.attribs.productId)) {
        return component.element.getAttribute(component.config.attribs.productId);
      } else {
        return null;
      }
    }

    const _getItemQuantity = () => {
      if (component.element.hasAttribute(component.config.attribs.quantity)) {
        return component.element.getAttribute(component.config.attribs.quantity);
      } else {
        return 1;
      }
    }

    const _showNearbyStores = (lon, lat) => {
      const productId = component.getProductId();
      const quantity = component.getItemQuantity();
      const loader = component.element.querySelector('.loader');
      loader.style.display = "block";

      if (isNaN(productId) || isNaN(lon) || isNaN(lat) || productId === null || lon === null || lat === null ) {
        return;
      }

      app.ajax.get({
        url: `/${productId}/checkStock.lookup?lon=${lon}&lat=${lat}&isBasketPage=${component.callFromBasket}&isWishlistPage=${component.callFromWishlist}&qty=${quantity}`,
        success: component.onSuccess,
        error: () => {
          component.displayNoResultsFoundMessage();
        }
      });
    }

    const _onSuccess = (response) => {
      const loader = component.element.querySelector('.loader');
      loader.style.display = "none";
      component.tidyUpStoreAvailability();
      component.modalInfo.insertAdjacentHTML('beforeend', response);

      const stores = component.element.querySelectorAll(component.config.selectors.storeAvailable);
      if (stores !== null) {
        stores.forEach((element, index) => {
          element.setAttribute(component.config.attribs.storePosition, index + 1);
        })
      }

      if (!component.callFromBasket) {
        const clickAndCollectAddToBasketButtons = component.element.querySelectorAll(component.config.selectors.productAddToBasketButton);

        if (clickAndCollectAddToBasketButtons !== null) {
          clickAndCollectAddToBasketButtons.forEach(button => _initialiseAddToBasket(
              button.getAttribute(component.config.attribs.productId),
              button.querySelector(component.config.selectors.productAddToBasket)));
        }
      }

      component.checkShowNearbyStoresResult();
      component.handleAddToBasketButtonDisabling();
    }

    const _storeButtonClicked = (event) => {
      const parent = event.target.parentElement.parentElement.parentElement.parentElement;

      if (parent !== null) {
        const position = parent.getAttribute(component.config.attribs.storePosition);

        if (position !== null) {
          window.dataLayer.push({
            'event': 'storePosition',
            'position': position
          });
        }
      }
    }

    const _initialiseAddToBasket = (productId, addToBasketElem) => {
      if (productId !== null && addToBasketElem !== null) {
        addToBasketElem.componentObject = new productAddToBasket().init(addToBasketElem, productId);
      }
    }

    const _disableButton = (button) => {
      if (button !== null && !button.hasAttribute('disabled')) {
        button.setAttribute('disabled', 'false');
      }
    }

    const _handleAddToBasketButtonDisabling = () => {
      const storeAvailabilityAddToBasketButtonElems = component.modalInfo.querySelectorAll(component.config.selectors.storeAvailabilityAddToBasketButton);
      let disableAddToBasket;
      let button;

      Array.from(storeAvailabilityAddToBasketButtonElems).forEach((element) => {
        if (element.hasAttribute(component.config.attribs.disallowAddToBasket)) {
          disableAddToBasket = element.getAttribute(component.config.attribs.disallowAddToBasket) === 'true';

          if (disableAddToBasket) {
            button = element.querySelector(component.config.selectors.productAddToBasket);
            component.disableButton(button);
          }
        }
      });

      const stores = component.element.querySelectorAll(component.config.selectors.storeAvailabilityElem);
      const storesAvailable = component.element.querySelectorAll('.hasStock');
      let sku = "";
      if(component.element.getAttribute(component.config.attribs.externalSku)) {
        sku = component.element.getAttribute(component.config.attribs.externalSku);
      } else {
        sku = component.getProductId()
      }

      enhancedEcom.stockCheck(stores, storesAvailable, sku);
    };

    const _checkShowNearbyStoresResult = () => {
      let errorShownFlag = false;
      component.storeAvailability = component.element.querySelector(component.config.selectors.storeAvailability);
      if (component.storeAvailability) {
        const upstreamErrors = component.storeAvailability.getAttribute(component.config.attribs.upstreamErrors);
        if (upstreamErrors === 'true') {
          component.displayUpstreamServiceErrorMessage();
          errorShownFlag = true;
        }
      }

      if (!errorShownFlag) {
        const storeAvailabilityElem = component.modalInfo.querySelector(component.config.selectors.storeAvailabilityElem);
        if (!storeAvailabilityElem) {
          component.displayNoResultsFoundMessage();
        }
      }
    }

    const _tidyUpStoreAvailability = () => {
      component.storeAvailability = component.element.querySelector(component.config.selectors.storeAvailability);
      component.storeAvailability && component.modalInfo.removeChild(component.storeAvailability);
    }

    const _displayNoResultsFoundMessage = () => {
      component.tidyUpStoreAvailability();
      const loader = component.element.querySelector('.loader');
      loader.style.display = "none";

      const storeAvailabilityContainer = document.createElement('div');
      storeAvailabilityContainer.setAttribute('class', 'storeAvailability_container');

      const storeAvailabilityErrorMessage = document.createElement('P');
      storeAvailabilityErrorMessage.setAttribute('class', 'storeAvailability_errorMessage');
      storeAvailabilityErrorMessage.innerHTML = `Sorry, we found no results for "${component.checkStockModalInput ? component.checkStockModalInput : component.search.value}", please try again.`;
      storeAvailabilityContainer.appendChild(storeAvailabilityErrorMessage);

      component.modalInfo.appendChild(storeAvailabilityContainer);
    }

    const _displayRequiredFieldErrorMessage = () => {
      component.tidyUpStoreAvailability();

      const REQUIRED_FIELD_ERROR_MESSAGE = `
      <div class="storeAvailability_container">
        <p class="storeAvailability_errorMessage">The postcode field is required.</p>
      </div>
      `;
      component.modalInfo.insertAdjacentHTML('beforeend', REQUIRED_FIELD_ERROR_MESSAGE);
    }

    const _displayUpstreamServiceErrorMessage = () => {
      component.tidyUpStoreAvailability();

      const UPSTREAM_SERVICE_FAILURE_ERROR_MESSAGE = `
      <div class="storeAvailability_container">
        <p class="storeAvailability_errorMessage">Sorry, it's taking longer than expected to check stock availability. Please try again later.</p>
      </div>
      `;
      component.modalInfo.insertAdjacentHTML('beforeend', UPSTREAM_SERVICE_FAILURE_ERROR_MESSAGE);
    }

    component.config = _config;
    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.onSearch = _onSearch;
    component.searchSuccessHandler = _searchSuccessHandler;
    component.showNearbyStores = _showNearbyStores;
    component.onSuccess = _onSuccess;
    component.populateStockCheckerFromLocalStorage = _populateStockCheckerFromLocalStorage;
    component.submitForm =  _submitForm;
    component.getProductId = _getProductId;
    component.disableButton = _disableButton;
    component.handleSuggestionClicked = _handleSuggestionClicked;
    component.getRecommendedLocations = _getRecommendedLocations;
    component.tidyUpStoreAvailability = _tidyUpStoreAvailability;
    component.displayNoResultsFoundMessage = _displayNoResultsFoundMessage;
    component.displayRequiredFieldErrorMessage = _displayRequiredFieldErrorMessage;
    component.handleAddToBasketButtonDisabling = _handleAddToBasketButtonDisabling;
    component.checkShowNearbyStoresResult = _checkShowNearbyStoresResult;
    component.getItemQuantity = _getItemQuantity;
    component.displayUpstreamServiceErrorMessage = _displayUpstreamServiceErrorMessage;
    component.storeButtonClicked = _storeButtonClicked;

    return component;
  };

  return productPageStockAvailabilityModal;
});
